const socialLinks = [
  {
    id: 1,
    url: "https://www.linkedin.com/in/farah-fournat/",
    icon: "fab fa-linkedin",
  },
  {
    id: 2,
    url: "https://github.com/Farahcodes",
    icon: "fab fa-github",
  },
];

export default socialLinks;
