import React from 'react';
import img from '../assets/images/programmer.svg';

const About = () => {
  return (
    <section className="section about" id="about">
      <div className="section-center about-center">
        <article className="about-img">
          <img src={img} className="about-photo" alt="about img" />
        </article>

        <article className="about-info">
          <div className="section-title about-title">
            <h2>about me</h2>
            <div className="underline"></div>
          </div>

          <p>
            I am a passionate web developer who loves building things
            with code. Having worked as a financial/data analyst, I
            always found myself confronted with both various and
            complex problem sets and challenging client demands.
          </p>
          <p>
            This allowed me to sharpen my attention to details and
            ability to adapt and learn about new products very
            quickly, but also to develop great analytical & problem
            solving skills. I'm also a fast learner, highly
            performance motivated and able to work under pressure. I
            thrive in diverse and multicultural environments.
          </p>
          <p>
            Even though I do a lot of programming in my professional
            and spare time, there are other things I treasure too. I
            love reading, hiking with my australian shepherd or doing
            yoga. I also have a passion for Spain: the colors, food,
            architecture, people and the Spanish lifestyle!
          </p>
          <p>
            I also love watching tv shows. Here are some of my
            favorites: True Detective, Dark, Arcane, Death Note
            (Anime), Attack On Titan (Anime), Mr. Robot, Game of
            Thrones, Dexter, Breaking Bad and Better Call Saul.
          </p>
          <p>
            And playing video games: God of War, Skyrim, Uncharted,
            Assassin's Creed, Tomb Raider, Crash Bandicoot, Oddworld:
            Abe's Oddysee, Stardew Valley.
          </p>
        </article>
      </div>
    </section>
  );
};

export default About;
