const links = [
    {
        id:1,
        path:'#home',
        text: 'Home'
    },
    {
        id:2,
        path:'#about',
        text: 'About'
    },
    {
        id:3,
        path:'#skills',
        text: 'Skills'
    },
    {
        id:4,
        path:'#projects',
        text: 'Projects'
    }
]

export default links